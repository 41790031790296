/*
 * @Descripttion :
 * @version      :
 * @Author       : min
 * @Date         : 2020-12-21 19:46:36
 * @LastEditors  : min
 * @LastEditTime : 2021-01-27 10:25:44
 */

import {
  fetch,
  post,
  put
  // patch, put
} from "./http";

// 后台登录
export function login(params) {
  return post("unlessApi/managelogin", params);
}

// 文件上传
export function uploadFile(params) {
  return post("unlessApi/uploadFile", params);
}

// 修改密码
export function editPassword(params) {
  return put("v1/user/changePassword", params);
}

// 获取用户操作信息
export function summaryUserHandle() {
  return fetch("v1/user/summaryUserHandle");
}
// 注册用户数
export function userCount() {
  return fetch("v1/user/userCount");
}


// 小程序用户列表
export function getUserList(params) {
  return fetch("v1/user/getUserList", params);
}

// banner列表
export function getBannerList(params) {
  return fetch("v1/banner/getBannerList", params);
}

// banner所有列表
export function getAllList(params) {
  return fetch("v1/banner/list", params);
}

// banner删除
export function deleteBanner(params) {
  return put("v1/banner/delete", params);
}

// 新增
export function createBanner(params) {
  return post("v1/banner/create", params);
}

// 车辆列表
export function getCarList(params) {
  return fetch("v1/car/getCarList", params);
}

// 求购列表
export function getBuyList(params) {
  return fetch("v1/buy/getBuyList", params);
}

// 更改车辆状态
export function updateCarStatus(params) {
  return put("v1/car/updateStatus", params);
}

// 更改求购状态
export function updateBuyStatus(params) {
  return put("v1/buy/updateStatus", params);
}
