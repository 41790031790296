/*
 * @Descripttion : axios 网络请求封装
 * @version      :
 * @Author       : min
 * @Date         : 2020-12-21 19:11:09
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 17:14:54
 */
import axios from "axios";
import router from "../router";

axios.defaults.timeout = 5000;
axios.defaults.baseURL = process.env.VUE_APP_URL;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.url.indexOf("/uploadFile") > -1) {
      config.headers = {
        "Content-Type": "multipart/form-data"
      };

      if (config.data) {
        let formData = new FormData();
        for (let i in config.data) {
          formData.append(i, config.data[i]);
        }
        config.data = formData;
      }
      console.log(config.data);
    } else {
      config.data = JSON.stringify(config.data);
      config.headers = {
        "Content-Type": "application/json"
      };
    }
    if (sessionStorage.getItem("_user_token_")) {
      config.headers["Authorization"] = "Bearer " + sessionStorage.getItem("_user_token_");
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      sessionStorage.setItem("_user_token_", response.headers.authorization);
    }
    if (response.data.code == 200) {
      return response;
    } else {
      if (response.data.code == 401) {
        router.push("/");
      }
      return Promise.reject(response.data);
    }
  },
  error => {
    if (error.response.data.code == 401) {
      router.push("/");
    }
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err);
    });
  });
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err);
    });
  });
}